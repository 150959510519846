<template>
  <div class="flex flex-col gap-8 md:mx-44 m-8">
    <ol class="flex flex-col gap-4 list-decimal text-2xl font-semibold">
      <li>
        TOP UP Balance
        <div class="flex flex-col gap-4 mt-4">
          <div class="flex flex-col gap-4">
            <div
              class="flex flex-row gap-4 items-center font-medium text-lg hover:text-green-1 cursor-pointer"
              @click="toggleTopUp1"
              :class="[show.topUp1 ? 'text-green-1' : '']"
            >
              <font-awesome-icon
                icon="fa-solid fa-circle-chevron-up"
                v-if="show.topUp1"
              />
              <font-awesome-icon
                icon="fa-solid fa-circle-chevron-down"
                v-else
              />
              <p>How do I see my balance on Balance?</p>
            </div>
            <p class="text-base font-normal ml-10" v-if="show.topUp1">
              Balance balance can be seen on the home page or on the Balance
              menu.
            </p>
          </div>
          <div class="flex flex-col gap-4">
            <div
              class="flex flex-row gap-4 items-center font-medium text-lg hover:text-green-1 cursor-pointer"
              @click="toggleTopUp2"
              :class="[show.topUp2 ? 'text-green-1' : '']"
            >
              <font-awesome-icon
                icon="fa-solid fa-circle-chevron-up"
                v-if="show.topUp2"
              />
              <font-awesome-icon
                icon="fa-solid fa-circle-chevron-down"
                v-else
              />
              <p>How do I top up my Balance balance?</p>
            </div>
            <div class="flex flex-col gap-4" v-if="show.topUp2">
              <div class="flex flex-col gap-4 ml-12">
                <div
                  class="flex flex-row gap-4 items-center font-medium text-lg hover:text-green-1 cursor-pointer"
                  @click="toggleTopUpBCA"
                  :class="[show.topUpBCA ? 'text-green-1' : '']"
                >
                  <font-awesome-icon
                    icon="fa-solid fa-circle-chevron-up"
                    v-if="show.topUpBCA"
                  />
                  <font-awesome-icon
                    icon="fa-solid fa-circle-chevron-down"
                    v-else
                  />
                  <p>Virtual Account BCA</p>
                </div>
                <ol
                  class="text-base font-bold ml-10 flex flex-col gap-2"
                  v-if="show.topUpBCA"
                >
                  <li>
                    a. Top up via BCA ATM
                    <ol class="text-base font-normal ml-8 list-decimal">
                      <li>Insert your ATM card and enter your PIN</li>
                      <li>
                        Select <strong>Transfer</strong> >
                        <strong>BCA Virtual Account</strong>
                      </li>
                      <li>
                        Enter your Virtual Account number (example:
                        14082xxxxxxxxxxx)
                      </li>
                      <li>Enter top up amount</li>
                      <li>Follow the next instructions to complete top-up</li>
                    </ol>
                  </li>
                  <li>
                    b. Top up via Other ATM
                    <ol class="text-base font-normal ml-8 list-decimal">
                      <li>Insert your ATM card and enter your PIN</li>
                      <li>
                        Choose <strong>Other Transaction</strong> and select
                        <strong>Transfer</strong>
                      </li>
                      <li>
                        Choose <strong>Other Bank</strong> and select
                        <strong>BCA</strong> or enter BCA bank code (014)
                      </li>
                      <li>
                        Enter your Virtual Account number (example:
                        14082xxxxxxxxxxx)
                      </li>
                      <li>Enter top up amount</li>
                      <li>Follow the next instructions to complete top-up</li>
                    </ol>
                  </li>
                  <li>
                    c. Top up via BCA Mobile Banking
                    <ol class="text-base font-normal ml-8 list-decimal">
                      <li>Login to m-BCA</li>
                      <li>
                        Select <strong>M-Transfer</strong> >
                        <strong>Transfer BCA Virtual Account</strong>
                      </li>
                      <li>
                        Enter your Virtual Account number (example:
                        14082xxxxxxxxxxx)
                      </li>
                      <li>Enter top up amount</li>
                      <li>Follow the next instructions to complete top-up</li>
                    </ol>
                  </li>
                  <li>
                    d. Top up via Other Mobile Banking
                    <ol class="text-base font-normal ml-8 list-decimal">
                      <li>Login to m-Banking apps</li>
                      <li>
                        Choose <strong>Transfer</strong> >
                        <strong>Other Bank</strong> and select
                        <strong>BCA</strong> or enter BCA bank code (014)
                      </li>
                      <li>
                        Enter your Virtual Account number (example:
                        14082xxxxxxxxxxx)
                      </li>
                      <li>Enter top up amount</li>
                      <li>Follow the next instructions to complete top-up</li>
                    </ol>
                  </li>
                  <li>
                    Note:
                    <ol class="text-base font-normal ml-8 list-decimal">
                      <li>Minimum top up is Rp10.000</li>
                      <li>
                        The admin fee is Rp3.000 and will be deducted from the
                        top up nominal
                      </li>
                      <li>
                        Free admin fee for Balance top-up above Rp500.000
                      </li>
                    </ol>
                  </li>
                </ol>
              </div>
              <div class="flex flex-col gap-4 ml-12">
                <div
                  class="flex flex-row gap-4 items-center font-medium text-lg hover:text-green-1 cursor-pointer"
                  @click="toggleTopUpBRI"
                  :class="[show.topUpBRI ? 'text-green-1' : '']"
                >
                  <font-awesome-icon
                    icon="fa-solid fa-circle-chevron-up"
                    v-if="show.topUpBRI"
                  />
                  <font-awesome-icon
                    icon="fa-solid fa-circle-chevron-down"
                    v-else
                  />
                  <p>Virtual Account BRI</p>
                </div>
                <ol
                  class="text-base font-bold ml-10 flex flex-col gap-2"
                  v-if="show.topUpBRI"
                >
                  <li>
                    a. Top up via BRI ATM
                    <ol class="text-base font-normal ml-8 list-decimal">
                      <li>Insert your ATM card and enter your PIN</li>
                      <li>
                        Select <strong>Transfer Lainnya</strong> >
                        <strong>Pembayaran</strong> >
                        <strong>Menu Lainnya</strong>
                      </li>
                      <li>Select <strong>BRIVA</strong></li>
                      <li>
                        Enter your Virtual Account number (example:
                        13009xxxxxxxxxxx)
                      </li>
                      <li>Enter top up amount</li>
                      <li>Follow the next instructions to complete top-up</li>
                    </ol>
                  </li>
                  <li>
                    b. Top up via Other ATM
                    <ol class="text-base font-normal ml-8 list-decimal">
                      <li>Insert your ATM card and enter your PIN</li>
                      <li>
                        Choose <strong>Other Transaction</strong> and select
                        <strong>Transfer</strong>
                      </li>
                      <li>
                        Choose <strong>Other Bank</strong> and select
                        <strong>BRI</strong> or enter BRI bank code (002)
                      </li>
                      <li>
                        Enter your Virtual Account number (example:
                        13009xxxxxxxxxxx)
                      </li>
                      <li>Enter top up amount</li>
                      <li>Follow the next instructions to complete top-up</li>
                    </ol>
                  </li>
                  <li>
                    c. Top up via BRI Mobile Banking
                    <ol class="text-base font-normal ml-8 list-decimal">
                      <li>Login to Brimo</li>
                      <li>Select <strong>BRIVA</strong></li>
                      <li>
                        Enter your Virtual Account number (example:
                        13009xxxxxxxxxxx)
                      </li>
                      <li>Enter top up amount</li>
                      <li>Follow the next instructions to complete top-up</li>
                    </ol>
                  </li>
                  <li>
                    d. Top up via Other Mobile Banking
                    <ol class="text-base font-normal ml-8 list-decimal">
                      <li>Login to m-Banking apps</li>
                      <li>
                        Choose <strong>Transfer</strong> >
                        <strong>Other Bank</strong> and select
                        <strong>BRI</strong> or enter BRI bank code (002)
                      </li>
                      <li>
                        Enter your Virtual Account number (example:
                        13009xxxxxxxxxxx)
                      </li>
                      <li>Enter top up amount</li>
                      <li>Follow the next instructions to complete top-up</li>
                    </ol>
                  </li>
                  <li>
                    Note:
                    <ol class="text-base font-normal ml-8 list-decimal">
                      <li>Minimum top up is Rp10.000</li>
                      <li>
                        The admin fee is Rp3.000 and will be deducted from the
                        top up nominal
                      </li>
                      <li>
                        Free admin fee for Balance top-up above Rp500.000
                      </li>
                    </ol>
                  </li>
                </ol>
              </div>
              <div class="flex flex-col gap-4 ml-12">
                <div
                  class="flex flex-row gap-4 items-center font-medium text-lg hover:text-green-1 cursor-pointer"
                  @click="toggleTopUpPermata"
                  :class="[show.topUpPermata ? 'text-green-1' : '']"
                >
                  <font-awesome-icon
                    icon="fa-solid fa-circle-chevron-up"
                    v-if="show.topUpPermata"
                  />
                  <font-awesome-icon
                    icon="fa-solid fa-circle-chevron-down"
                    v-else
                  />
                  <p>Virtual Account Permata</p>
                </div>
                <ol
                  class="text-base font-bold ml-10 flex flex-col gap-2"
                  v-if="show.topUpPermata"
                >
                  <li>
                    a. Top up via Permata ATM
                    <ol class="text-base font-normal ml-8 list-decimal">
                      <li>Insert your ATM card and enter your PIN</li>
                      <li>
                        Select <strong>Transaksi</strong> >
                        <strong>Transfer ke Rekening PermataBank</strong> or
                        select <strong>Pembayaran</strong> >
                        <strong>Virtual Account</strong>
                      </li>
                      <li>Select <strong>PermataVA</strong></li>
                      <li>
                        Enter your Virtual Account number (example:
                        8687xxxxxxxxxxx)
                      </li>
                      <li>Enter top up amount</li>
                      <li>Follow the next instructions to complete top-up</li>
                    </ol>
                  </li>
                  <li>
                    b. Top up via Other ATM
                    <ol class="text-base font-normal ml-8 list-decimal">
                      <li>Insert your ATM card and enter your PIN</li>
                      <li>
                        Choose <strong>Other Transaction</strong> and select
                        <strong>Transfer</strong>
                      </li>
                      <li>
                        Choose <strong>Other Bank</strong> and select
                        <strong>Permata</strong> or enter Permata bank code
                        (013)
                      </li>
                      <li>
                        Enter your Virtual Account number (example:
                        8687xxxxxxxxxxx)
                      </li>
                      <li>Enter top up amount</li>
                      <li>Follow the next instructions to complete top-up</li>
                    </ol>
                  </li>
                  <li>
                    c. Top up via Permata Mobile Banking
                    <ol class="text-base font-normal ml-8 list-decimal">
                      <li>Login to PermataMobile X</li>
                      <li>
                        Select <strong>Bayar Tagihan</strong> >
                        <strong>Akun Virtual</strong>
                      </li>
                      <li>
                        Enter your Virtual Account number (example:
                        8687xxxxxxxxxxx)
                      </li>
                      <li>Enter top up amount</li>
                      <li>Follow the next instructions to complete top-up</li>
                    </ol>
                  </li>
                  <li>
                    d. Top up via Other Mobile Banking
                    <ol class="text-base font-normal ml-8 list-decimal">
                      <li>Login to m-Banking apps</li>
                      <li>
                        Choose <strong>Transfer</strong> >
                        <strong>Other Bank</strong> and select
                        <strong>Permata</strong> or enter Permata bank code
                        (013)
                      </li>
                      <li>
                        Enter your Virtual Account number (example:
                        8687xxxxxxxxxxx)
                      </li>
                      <li>Enter top up amount</li>
                      <li>Follow the next instructions to complete top-up</li>
                    </ol>
                  </li>
                  <li>
                    Note:
                    <ol class="text-base font-normal ml-8 list-decimal">
                      <li>Minimum top up is Rp10.000</li>
                      <li>
                        The admin fee is Rp3.000 and will be deducted from the
                        top up nominal
                      </li>
                      <li>
                        Free admin fee for Balance top-up above Rp500.000
                      </li>
                    </ol>
                  </li>
                </ol>
              </div>
            </div>
          </div>
          <div class="flex flex-col gap-4">
            <div
              class="flex flex-row gap-4 items-center font-medium text-lg hover:text-green-1 cursor-pointer"
              @click="toggleTopUp3"
              :class="[show.topUp3 ? 'text-green-1' : '']"
            >
              <font-awesome-icon
                icon="fa-solid fa-circle-chevron-up"
                v-if="show.topUp3"
              />
              <font-awesome-icon
                icon="fa-solid fa-circle-chevron-down"
                v-else
              />
              <p>How to view Balance transaction history?</p>
            </div>
            <p class="text-base font-normal ml-10" v-if="show.topUp3">
              Balance transaction history can be seen in the Balance menu.
            </p>
          </div>
          <div class="flex flex-col gap-4">
            <div
              class="flex flex-row gap-4 items-center font-medium text-lg hover:text-green-1 cursor-pointer"
              @click="toggleTopUp4"
              :class="[show.topUp4 ? 'text-green-1' : '']"
            >
              <font-awesome-icon
                icon="fa-solid fa-circle-chevron-up"
                v-if="show.topUp4"
              />
              <font-awesome-icon
                icon="fa-solid fa-circle-chevron-down"
                v-else
              />
              <p>Can we see Balance transactions in the last month</p>
            </div>
            <ol
              class="text-base font-normal ml-10 list-decimal"
              v-if="show.topUp4"
            >
              <li>Open the Balance menu</li>
              <li>Click the ‘clock’ icon on the right.</li>
              <li>
                Set the start and end date to see the transactions during that
                period.
              </li>
            </ol>
          </div>
        </div>
      </li>
      <li>
        BANK TRANSFER
        <div class="flex flex-col gap-4 mt-4">
          <div class="flex flex-col gap-4">
            <div
              class="flex flex-row gap-4 items-center font-medium text-lg hover:text-green-1 cursor-pointer"
              @click="toggleTransfer1"
              :class="[show.transfer1 ? 'text-green-1' : '']"
            >
              <font-awesome-icon
                icon="fa-solid fa-circle-chevron-up"
                v-if="show.transfer1"
              />
              <font-awesome-icon
                icon="fa-solid fa-circle-chevron-down"
                v-else
              />
              <p>How to withdraw Balance balance?</p>
            </div>
            <p class="text-base font-normal ml-10" v-if="show.transfer1">
              You can transfer your Balance balance to your bank account .
            </p>
          </div>
          <div class="flex flex-col gap-4">
            <div
              class="flex flex-row gap-4 items-center font-medium text-lg hover:text-green-1 cursor-pointer"
              @click="toggleTransfer2"
              :class="[show.transfer2 ? 'text-green-1' : '']"
            >
              <font-awesome-icon
                icon="fa-solid fa-circle-chevron-up"
                v-if="show.transfer2"
              />
              <font-awesome-icon
                icon="fa-solid fa-circle-chevron-down"
                v-else
              />
              <p>
                Will I be charged an additional fee when transferring to bank
                account other than BCA, BRI or PermataBank
              </p>
            </div>
            <p class="text-base font-normal ml-10" v-if="show.transfer2">
              <strong>Yes.</strong> Transaction fees are charged if you withdraw
              their Balance balance to a bank other than BCA, BRI, and
              PermataBank. The amount is in accordance with the provisions of
              the destination bank.
            </p>
          </div>
        </div>
      </li>
      <li>
        HOLD
        <div class="flex flex-col gap-4 mt-4">
          <div
            class="flex flex-row gap-4 items-center font-medium text-lg hover:text-green-1 cursor-pointer"
            @click="toggleHold"
            :class="[show.hold ? 'text-green-1' : '']"
          >
            <font-awesome-icon
              icon="fa-solid fa-circle-chevron-up"
              v-if="show.hold"
            />
            <font-awesome-icon icon="fa-solid fa-circle-chevron-down" v-else />
            <p>What is the HOLD Amount on my Balance account?</p>
          </div>
          <p class="text-base font-normal ml-10" v-if="show.hold">
            The hold amount is the funds held by Tripwe which will be disbursed
            when the admin completes the package ordered by trippers.
          </p>
        </div>
      </li>
      <li>
        FORGET Balance PIN
        <div class="flex flex-col gap-4 mt-4">
          <div
            class="flex flex-row gap-4 items-center font-medium text-lg hover:text-green-1 cursor-pointer"
            @click="toggleForgot"
            :class="[show.forgot ? 'text-green-1' : '']"
          >
            <font-awesome-icon
              icon="fa-solid fa-circle-chevron-up"
              v-if="show.forgot"
            />
            <font-awesome-icon icon="fa-solid fa-circle-chevron-down" v-else />
            <p>I forgot my Balance PIN, what should I do?</p>
          </div>
          <p class="text-base font-normal ml-10" v-if="show.forgot">
            Go to the Balance menu and enter a new account PIN.
          </p>
        </div>
      </li>
    </ol>
  </div>
</template>
<script>
export default {
  name: "TripwepayFAQ",
  data() {
    return {
      show: {
        topUp1: false,
        topUp2: false,
        topUp3: false,
        topUp4: false,
        topUpBCA: false,
        topUpBRI: false,
        topUpPermata: false,
        transfer1: false,
        transfer2: false,
        hold: false,
        forgot: false,
      },
    };
  },
  created() {
    this.openAccordionWithUrl();
  },
  methods: {
    toggleTopUp1() {
      this.show.topUp1 = !this.show.topUp1;
      this.show.topUp2 = false;
      this.show.topUp3 = false;
      this.show.topUp4 = false;
      this.show.topUpBCA = false;
      this.show.topUpBRI = false;
      this.show.topUpPermata = false;
      this.show.transfer1 = false;
      this.show.transfer2 = false;
      this.show.hold = false;
      this.show.forgot = false;
    },
    toggleTopUp2() {
      this.show.topUp1 = false;
      this.show.topUp2 = !this.show.topUp2;
      this.show.topUp3 = false;
      this.show.topUp4 = false;
      this.show.topUpBCA = false;
      this.show.topUpBRI = false;
      this.show.topUpPermata = false;
      this.show.transfer1 = false;
      this.show.transfer2 = false;
      this.show.hold = false;
      this.show.forgot = false;
    },
    toggleTopUp3() {
      this.show.topUp1 = false;
      this.show.topUp2 = false;
      this.show.topUp3 = !this.show.topUp3;
      this.show.topUp4 = false;
      this.show.topUpBCA = false;
      this.show.topUpBRI = false;
      this.show.topUpPermata = false;
      this.show.transfer1 = false;
      this.show.transfer2 = false;
      this.show.hold = false;
      this.show.forgot = false;
    },
    toggleTopUp4() {
      this.show.topUp1 = false;
      this.show.topUp2 = false;
      this.show.topUp3 = false;
      this.show.topUp4 = !this.show.topUp4;
      this.show.topUpBCA = false;
      this.show.topUpBRI = false;
      this.show.topUpPermata = false;
      this.show.transfer1 = false;
      this.show.transfer2 = false;
      this.show.hold = false;
      this.show.forgot = false;
    },
    toggleTopUpBCA() {
      this.show.topUp1 = false;
      this.show.topUp2 = true;
      this.show.topUp3 = false;
      this.show.topUp4 = false;
      this.show.topUpBCA = !this.show.topUpBCA;
      this.show.topUpBRI = false;
      this.show.topUpPermata = false;
      this.show.transfer1 = false;
      this.show.transfer2 = false;
      this.show.hold = false;
      this.show.forgot = false;
    },
    toggleTopUpBRI() {
      this.show.topUp1 = false;
      this.show.topUp2 = true;
      this.show.topUp3 = false;
      this.show.topUp4 = false;
      this.show.topUpBCA = false;
      this.show.topUpBRI = !this.show.topUpBRI;
      this.show.topUpPermata = false;
      this.show.transfer1 = false;
      this.show.transfer2 = false;
      this.show.hold = false;
      this.show.forgot = false;
    },
    toggleTopUpPermata() {
      this.show.topUp1 = false;
      this.show.topUp2 = true;
      this.show.topUp3 = false;
      this.show.topUp4 = false;
      this.show.topUpBCA = false;
      this.show.topUpBRI = false;
      this.show.topUpPermata = !this.show.topUpPermata;
      this.show.transfer1 = false;
      this.show.transfer2 = false;
      this.show.hold = false;
      this.show.forgot = false;
    },
    toggleTransfer1() {
      this.show.topUp1 = false;
      this.show.topUp2 = false;
      this.show.topUp3 = false;
      this.show.topUp4 = false;
      this.show.topUpBCA = false;
      this.show.topUpBRI = false;
      this.show.topUpPermata = false;
      this.show.transfer1 = !this.show.transfer1;
      this.show.transfer2 = false;
      this.show.hold = false;
      this.show.forgot = false;
    },
    toggleTransfer2() {
      this.show.topUp1 = false;
      this.show.topUp2 = false;
      this.show.topUp3 = false;
      this.show.topUp4 = false;
      this.show.topUpBCA = false;
      this.show.topUpBRI = false;
      this.show.topUpPermata = false;
      this.show.transfer1 = false;
      this.show.transfer2 = !this.show.transfer2;
      this.show.hold = false;
      this.show.forgot = false;
    },
    toggleHold() {
      this.show.topUp1 = false;
      this.show.topUp2 = false;
      this.show.topUp3 = false;
      this.show.topUp4 = false;
      this.show.topUpBCA = false;
      this.show.topUpBRI = false;
      this.show.topUpPermata = false;
      this.show.transfer1 = false;
      this.show.transfer2 = false;
      this.show.hold = !this.show.hold;
      this.show.forgot = false;
    },
    toggleForgot() {
      this.show.topUp1 = false;
      this.show.topUp2 = false;
      this.show.topUp3 = false;
      this.show.topUp4 = false;
      this.show.topUpBCA = false;
      this.show.topUpBRI = false;
      this.show.topUpPermata = false;
      this.show.transfer1 = false;
      this.show.transfer2 = false;
      this.show.hold = false;
      this.show.forgot = !this.show.forgot;
    },
    openAccordionWithUrl() {
      if (
        this.$route.params.id === null ||
        this.$route.params.id === "" ||
        this.$route.params.id === "topup1"
      ) {
        this.show.topUp1 = true;
      } else if (this.$route.params.id === "topup2") {
        this.show.topUp2 = true;
      } else if (this.$route.params.id === "topup3") {
        this.show.topUp3 = true;
      } else if (this.$route.params.id === "topup4") {
        this.show.topUp4 = true;
      } else if (this.$route.params.id === "topupbca") {
        this.show.topUp2 = true;
        this.show.topUpBCA = true;
      } else if (this.$route.params.id === "topupbri") {
        this.show.topUp2 = true;
        this.show.topUpBRI = true;
      } else if (this.$route.params.id === "topuppermata") {
        this.show.topUp2 = true;
        this.show.topUpPermata = true;
      } else if (this.$route.params.id === "transfer1") {
        this.show.transfer1 = true;
      } else if (this.$route.params.id === "transfer2") {
        this.show.transfer2 = true;
      } else if (this.$route.params.id === "hold") {
        this.show.hold = true;
      } else if (this.$route.params.id === "forgot") {
        this.show.forgot = true;
      }
    },
  },
};
</script>
